/* tslint:disable */
/* eslint-disable */
/**
 * Bino BI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataSource
 */
export interface DataSource {
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    reportId: string;
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    description?: string;
    /**
     * 
     * @type {any}
     * @memberof DataSource
     */
    settings?: any | null;
    /**
     * 
     * @type {Date}
     * @memberof DataSource
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DataSource
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the DataSource interface.
 */
export function instanceOfDataSource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reportId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function DataSourceFromJSON(json: any): DataSource {
    return DataSourceFromJSONTyped(json, false);
}

export function DataSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reportId': json['reportId'],
        'name': json['name'],
        'slug': json['slug'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'settings': !exists(json, 'settings') ? undefined : json['settings'],
        'updatedAt': (new Date(json['updatedAt'])),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function DataSourceToJSON(value?: DataSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reportId': value.reportId,
        'name': value.name,
        'slug': value.slug,
        'description': value.description,
        'settings': value.settings,
        'updatedAt': (value.updatedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
    };
}

