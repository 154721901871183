import loadable from "@loadable/component"
import { Route, Routes } from "react-router-dom"
import SessionOutlet from "../sessions/SessionOutlet"
import AppFrameOutlet from "./AppFrameOutlet"
import RedirectToPersonal from "./RedirectToPersonal"

const Sessions = loadable(() => import('../sessions/index'))
const SetupPage = loadable(() => import('../setup/SetupPage'))
const ReportEditor = loadable(() => import('../report-editor/ReportEditor'))
const SettingsPage = loadable(() => import('../tenants/SettingsPage'))
const WorkbenchPage = loadable(() => import('../workbench/WorkbenchPage'))


const AppRoutes = () => {
    return <Routes>
        <Route path="sessions/*" element={<Sessions />} />
        <Route element={<SessionOutlet />}>
            <Route path="editor/:tenantId/reports/:reportId/*" element={<ReportEditor />} />
            <Route element={<AppFrameOutlet />}>
                <Route path="/" caseSensitive element={<RedirectToPersonal />} />
                <Route path="setup" element={<SetupPage />} />
                <Route path="workbenches/:tenantId" element={<WorkbenchPage />} />
                <Route path="settings/:tenantId/*" element={<SettingsPage />} >
                    <Route path="general" element={<div>General</div>} />
                    <Route path="members" element={<div>Members</div>} />
                    <Route path="secrets" element={<div>Secrets</div>} />
                    <Route path="templates" element={<div>Templates</div>} />
                    <Route path="logs" element={<div>Logs</div>} />
                </Route>
            </Route>
        </Route>
        <Route path="*" element={<div>Not Found</div>} />
    </Routes>
}



export default AppRoutes;