/* tslint:disable */
/* eslint-disable */
/**
 * Bino BI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    engineVersion: string;
    /**
     * 
     * @type {Date}
     * @memberof Report
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Report
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Report interface.
 */
export function instanceOfReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tenantId" in value;
    isInstance = isInstance && "engineVersion" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function ReportFromJSON(json: any): Report {
    return ReportFromJSONTyped(json, false);
}

export function ReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Report {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': json['tenantId'],
        'engineVersion': json['engineVersion'],
        'updatedAt': (new Date(json['updatedAt'])),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function ReportToJSON(value?: Report | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'engineVersion': value.engineVersion,
        'updatedAt': (value.updatedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
    };
}

