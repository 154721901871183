/* tslint:disable */
/* eslint-disable */
/**
 * Bino BI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    dataSourceId: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    contentType: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    state: string;
    /**
     * 
     * @type {Date}
     * @memberof Entity
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Entity
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Entity interface.
 */
export function instanceOfEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tenantId" in value;
    isInstance = isInstance && "dataSourceId" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function EntityFromJSON(json: any): Entity {
    return EntityFromJSONTyped(json, false);
}

export function EntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Entity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': json['tenantId'],
        'dataSourceId': json['dataSourceId'],
        'filename': json['filename'],
        'contentType': json['contentType'],
        'state': json['state'],
        'updatedAt': (new Date(json['updatedAt'])),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function EntityToJSON(value?: Entity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'dataSourceId': value.dataSourceId,
        'filename': value.filename,
        'contentType': value.contentType,
        'state': value.state,
        'updatedAt': (value.updatedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
    };
}

