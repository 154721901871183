/* tslint:disable */
/* eslint-disable */
/**
 * Bino BI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Account,
  AccountSignUp403Response,
  CreateDataSourceData,
  CreateTenantData,
  CreateWorkbenchData,
  DataSource,
  DatasourcePayload,
  Details,
  Entity,
  LoginData,
  RefreshSessionData,
  RegistrationData,
  RenderReportRequestData,
  Report,
  ReportContent,
  RequestInstructionsData,
  ResetPasswordData,
  Session,
  SessionToken,
  Style,
  Tenant,
  TenantSimplified,
  Translation,
  UpdatePasswordData,
  UpdateStyleData,
  UpdateTranslationData,
  UpdateWorkbenchData,
  UserId,
  Workbench,
} from '../models/index';
import {
    AccountFromJSON,
    AccountToJSON,
    AccountSignUp403ResponseFromJSON,
    AccountSignUp403ResponseToJSON,
    CreateDataSourceDataFromJSON,
    CreateDataSourceDataToJSON,
    CreateTenantDataFromJSON,
    CreateTenantDataToJSON,
    CreateWorkbenchDataFromJSON,
    CreateWorkbenchDataToJSON,
    DataSourceFromJSON,
    DataSourceToJSON,
    DatasourcePayloadFromJSON,
    DatasourcePayloadToJSON,
    DetailsFromJSON,
    DetailsToJSON,
    EntityFromJSON,
    EntityToJSON,
    LoginDataFromJSON,
    LoginDataToJSON,
    RefreshSessionDataFromJSON,
    RefreshSessionDataToJSON,
    RegistrationDataFromJSON,
    RegistrationDataToJSON,
    RenderReportRequestDataFromJSON,
    RenderReportRequestDataToJSON,
    ReportFromJSON,
    ReportToJSON,
    ReportContentFromJSON,
    ReportContentToJSON,
    RequestInstructionsDataFromJSON,
    RequestInstructionsDataToJSON,
    ResetPasswordDataFromJSON,
    ResetPasswordDataToJSON,
    SessionFromJSON,
    SessionToJSON,
    SessionTokenFromJSON,
    SessionTokenToJSON,
    StyleFromJSON,
    StyleToJSON,
    TenantFromJSON,
    TenantToJSON,
    TenantSimplifiedFromJSON,
    TenantSimplifiedToJSON,
    TranslationFromJSON,
    TranslationToJSON,
    UpdatePasswordDataFromJSON,
    UpdatePasswordDataToJSON,
    UpdateStyleDataFromJSON,
    UpdateStyleDataToJSON,
    UpdateTranslationDataFromJSON,
    UpdateTranslationDataToJSON,
    UpdateWorkbenchDataFromJSON,
    UpdateWorkbenchDataToJSON,
    UserIdFromJSON,
    UserIdToJSON,
    WorkbenchFromJSON,
    WorkbenchToJSON,
} from '../models/index';

export interface AccountLoginRequest {
    loginData: LoginData;
}

export interface AccountRefreshSessionRequest {
    refreshSessionData: RefreshSessionData;
}

export interface AccountRequestInstructionsRequest {
    requestInstructionsData: RequestInstructionsData;
}

export interface AccountResetPasswordRequest {
    resetPasswordData: ResetPasswordData;
}

export interface AccountSessionsIndexRequest {
    courser?: string;
    size?: number;
}

export interface AccountSignUpRequest {
    registrationData: RegistrationData;
}

export interface AccountUpdateCredentialsRequest {
    updatePasswordData: UpdatePasswordData;
}

export interface AccountUpdateDetailsRequest {
    details: Details;
}

export interface CreateReportDataSourceRequest {
    tenantId: string;
    id: string;
    createDataSourceData: CreateDataSourceData;
}

export interface CreateReportStyleRequest {
    tenantId: string;
    reportId: string;
    updateStyleData: UpdateStyleData;
}

export interface CreateReportTranslationRequest {
    tenantId: string;
    reportId: string;
    updateTranslationData: UpdateTranslationData;
}

export interface CreateWorkbenchRequest {
    tenantId: string;
    createWorkbenchData: CreateWorkbenchData;
}

export interface DeleteReportDataSourceRequest {
    tenantId: string;
    reportId: string;
    id: string;
}

export interface DeleteReportDataSourceEntityRequest {
    tenantId: string;
    reportId: string;
    dataSourceId: string;
    id: string;
}

export interface DeleteReportStyleRequest {
    tenantId: string;
    reportId: string;
    id: string;
}

export interface DeleteReportTranslationRequest {
    tenantId: string;
    reportId: string;
    id: string;
}

export interface DeleteWorkbenchRequest {
    tenantId: string;
    workbenchId: string;
}

export interface GetAllReportDataSourceEntitiesRequest {
    tenantId: string;
    reportId: string;
    dataSourceId: string;
}

export interface GetAllReportDataSourcesPayloadsRequest {
    tenantId: string;
    id: string;
}

export interface GetReportRequest {
    tenantId: string;
    id: string;
}

export interface GetReportContentRequest {
    tenantId: string;
    reportId: string;
}

export interface GetReportDataSourcePayloadRequest {
    tenantId: string;
    reportId: string;
    id: string;
}

export interface GetReportDataSourcesRequest {
    tenantId: string;
    id: string;
}

export interface GetReportRenderResourceRequest {
    tenantId: string;
    reportId: string;
    renderReportRequestData: RenderReportRequestData;
}

export interface GetReportStylesRequest {
    tenantId: string;
    reportId: string;
}

export interface GetReportTranslationsRequest {
    tenantId: string;
    reportId: string;
}

export interface GetWorkbenchesRequest {
    tenantId: string;
    cursor?: string;
}

export interface TenantsCreateRequest {
    createTenantData: CreateTenantData;
}

export interface UpdateReportContentRequest {
    tenantId: string;
    reportId: string;
    body: any | null;
}

export interface UpdateReportStyleRequest {
    tenantId: string;
    reportId: string;
    id: string;
    updateStyleData: UpdateStyleData;
}

export interface UpdateReportTranslationRequest {
    tenantId: string;
    reportId: string;
    id: string;
    updateTranslationData: UpdateTranslationData;
}

export interface UpdateWorkbenchRequest {
    tenantId: string;
    workbenchId: string;
    updateWorkbenchData: UpdateWorkbenchData;
}

export interface UploadReportEntitiesRequest {
    tenantId: string;
    reportId: string;
    dataSourceId: string;
    files?: Array<Blob>;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async accountDetailsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accounts/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     */
    async accountDetails(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.accountDetailsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async accountLoginRaw(requestParameters: AccountLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SessionToken>> {
        if (requestParameters.loginData === null || requestParameters.loginData === undefined) {
            throw new runtime.RequiredError('loginData','Required parameter requestParameters.loginData was null or undefined when calling accountLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/accounts/sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginDataToJSON(requestParameters.loginData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionTokenFromJSON(jsonValue));
    }

    /**
     */
    async accountLogin(requestParameters: AccountLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SessionToken> {
        const response = await this.accountLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * close the session related to the given JWT token. After that action the refresh token can\'t use to generate a new jwt token. Generates tokens sill valid until they expire.
     */
    async accountLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accounts/sessions`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * close the session related to the given JWT token. After that action the refresh token can\'t use to generate a new jwt token. Generates tokens sill valid until they expire.
     */
    async accountLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountLogoutRaw(initOverrides);
    }

    /**
     * Generate a new jwt token by passing a valid refresh token
     */
    async accountRefreshSessionRaw(requestParameters: AccountRefreshSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SessionToken>> {
        if (requestParameters.refreshSessionData === null || requestParameters.refreshSessionData === undefined) {
            throw new runtime.RequiredError('refreshSessionData','Required parameter requestParameters.refreshSessionData was null or undefined when calling accountRefreshSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/accounts/sessions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshSessionDataToJSON(requestParameters.refreshSessionData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionTokenFromJSON(jsonValue));
    }

    /**
     * Generate a new jwt token by passing a valid refresh token
     */
    async accountRefreshSession(requestParameters: AccountRefreshSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SessionToken> {
        const response = await this.accountRefreshSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async accountRequestInstructionsRaw(requestParameters: AccountRequestInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestInstructionsData === null || requestParameters.requestInstructionsData === undefined) {
            throw new runtime.RequiredError('requestInstructionsData','Required parameter requestParameters.requestInstructionsData was null or undefined when calling accountRequestInstructions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/accounts/reset-password-instructions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestInstructionsDataToJSON(requestParameters.requestInstructionsData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async accountRequestInstructions(requestParameters: AccountRequestInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountRequestInstructionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async accountResetPasswordRaw(requestParameters: AccountResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resetPasswordData === null || requestParameters.resetPasswordData === undefined) {
            throw new runtime.RequiredError('resetPasswordData','Required parameter requestParameters.resetPasswordData was null or undefined when calling accountResetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/accounts/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordDataToJSON(requestParameters.resetPasswordData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async accountResetPassword(requestParameters: AccountResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async accountSessionsIndexRaw(requestParameters: AccountSessionsIndexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        if (requestParameters.courser !== undefined) {
            queryParameters['courser'] = requestParameters.courser;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accounts/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async accountSessionsIndex(requestParameters: AccountSessionsIndexRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.accountSessionsIndexRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async accountSignUpRaw(requestParameters: AccountSignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserId>> {
        if (requestParameters.registrationData === null || requestParameters.registrationData === undefined) {
            throw new runtime.RequiredError('registrationData','Required parameter requestParameters.registrationData was null or undefined when calling accountSignUp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/accounts/registration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationDataToJSON(requestParameters.registrationData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserIdFromJSON(jsonValue));
    }

    /**
     */
    async accountSignUp(requestParameters: AccountSignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserId> {
        const response = await this.accountSignUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async accountUpdateCredentialsRaw(requestParameters: AccountUpdateCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updatePasswordData === null || requestParameters.updatePasswordData === undefined) {
            throw new runtime.RequiredError('updatePasswordData','Required parameter requestParameters.updatePasswordData was null or undefined when calling accountUpdateCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accounts/credentials`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePasswordDataToJSON(requestParameters.updatePasswordData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async accountUpdateCredentials(requestParameters: AccountUpdateCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountUpdateCredentialsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async accountUpdateDetailsRaw(requestParameters: AccountUpdateDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.details === null || requestParameters.details === undefined) {
            throw new runtime.RequiredError('details','Required parameter requestParameters.details was null or undefined when calling accountUpdateDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accounts/details`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DetailsToJSON(requestParameters.details),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     */
    async accountUpdateDetails(requestParameters: AccountUpdateDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.accountUpdateDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createReportDataSourceRaw(requestParameters: CreateReportDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSource>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling createReportDataSource.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createReportDataSource.');
        }

        if (requestParameters.createDataSourceData === null || requestParameters.createDataSourceData === undefined) {
            throw new runtime.RequiredError('createDataSourceData','Required parameter requestParameters.createDataSourceData was null or undefined when calling createReportDataSource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{id}/data_sources`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDataSourceDataToJSON(requestParameters.createDataSourceData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSourceFromJSON(jsonValue));
    }

    /**
     */
    async createReportDataSource(requestParameters: CreateReportDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSource> {
        const response = await this.createReportDataSourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createReportStyleRaw(requestParameters: CreateReportStyleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Style>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling createReportStyle.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling createReportStyle.');
        }

        if (requestParameters.updateStyleData === null || requestParameters.updateStyleData === undefined) {
            throw new runtime.RequiredError('updateStyleData','Required parameter requestParameters.updateStyleData was null or undefined when calling createReportStyle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/styles`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStyleDataToJSON(requestParameters.updateStyleData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StyleFromJSON(jsonValue));
    }

    /**
     */
    async createReportStyle(requestParameters: CreateReportStyleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Style> {
        const response = await this.createReportStyleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createReportTranslationRaw(requestParameters: CreateReportTranslationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Translation>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling createReportTranslation.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling createReportTranslation.');
        }

        if (requestParameters.updateTranslationData === null || requestParameters.updateTranslationData === undefined) {
            throw new runtime.RequiredError('updateTranslationData','Required parameter requestParameters.updateTranslationData was null or undefined when calling createReportTranslation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/translations`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTranslationDataToJSON(requestParameters.updateTranslationData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TranslationFromJSON(jsonValue));
    }

    /**
     */
    async createReportTranslation(requestParameters: CreateReportTranslationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Translation> {
        const response = await this.createReportTranslationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createWorkbenchRaw(requestParameters: CreateWorkbenchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workbench>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling createWorkbench.');
        }

        if (requestParameters.createWorkbenchData === null || requestParameters.createWorkbenchData === undefined) {
            throw new runtime.RequiredError('createWorkbenchData','Required parameter requestParameters.createWorkbenchData was null or undefined when calling createWorkbench.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/workbenches/{tenantId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkbenchDataToJSON(requestParameters.createWorkbenchData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkbenchFromJSON(jsonValue));
    }

    /**
     */
    async createWorkbench(requestParameters: CreateWorkbenchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workbench> {
        const response = await this.createWorkbenchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteReportDataSourceRaw(requestParameters: DeleteReportDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling deleteReportDataSource.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling deleteReportDataSource.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteReportDataSource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/data_sources/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteReportDataSource(requestParameters: DeleteReportDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReportDataSourceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteReportDataSourceEntityRaw(requestParameters: DeleteReportDataSourceEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling deleteReportDataSourceEntity.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling deleteReportDataSourceEntity.');
        }

        if (requestParameters.dataSourceId === null || requestParameters.dataSourceId === undefined) {
            throw new runtime.RequiredError('dataSourceId','Required parameter requestParameters.dataSourceId was null or undefined when calling deleteReportDataSourceEntity.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteReportDataSourceEntity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/data_sources/{dataSourceId}/entities/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"dataSourceId"}}`, encodeURIComponent(String(requestParameters.dataSourceId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteReportDataSourceEntity(requestParameters: DeleteReportDataSourceEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReportDataSourceEntityRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteReportStyleRaw(requestParameters: DeleteReportStyleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling deleteReportStyle.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling deleteReportStyle.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteReportStyle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/style/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteReportStyle(requestParameters: DeleteReportStyleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReportStyleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteReportTranslationRaw(requestParameters: DeleteReportTranslationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling deleteReportTranslation.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling deleteReportTranslation.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteReportTranslation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/translations/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteReportTranslation(requestParameters: DeleteReportTranslationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReportTranslationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteWorkbenchRaw(requestParameters: DeleteWorkbenchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling deleteWorkbench.');
        }

        if (requestParameters.workbenchId === null || requestParameters.workbenchId === undefined) {
            throw new runtime.RequiredError('workbenchId','Required parameter requestParameters.workbenchId was null or undefined when calling deleteWorkbench.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/workbenches/{tenantId}/workbenches/{workbenchId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"workbenchId"}}`, encodeURIComponent(String(requestParameters.workbenchId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteWorkbench(requestParameters: DeleteWorkbenchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteWorkbenchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllReportDataSourceEntitiesRaw(requestParameters: GetAllReportDataSourceEntitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Entity>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getAllReportDataSourceEntities.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getAllReportDataSourceEntities.');
        }

        if (requestParameters.dataSourceId === null || requestParameters.dataSourceId === undefined) {
            throw new runtime.RequiredError('dataSourceId','Required parameter requestParameters.dataSourceId was null or undefined when calling getAllReportDataSourceEntities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/data_sources/{dataSourceId}/entities`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"dataSourceId"}}`, encodeURIComponent(String(requestParameters.dataSourceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EntityFromJSON));
    }

    /**
     */
    async getAllReportDataSourceEntities(requestParameters: GetAllReportDataSourceEntitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Entity>> {
        const response = await this.getAllReportDataSourceEntitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllReportDataSourcesPayloadsRaw(requestParameters: GetAllReportDataSourcesPayloadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasourcePayload>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getAllReportDataSourcesPayloads.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllReportDataSourcesPayloads.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{id}/data_sources_payloads`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DatasourcePayloadFromJSON));
    }

    /**
     */
    async getAllReportDataSourcesPayloads(requestParameters: GetAllReportDataSourcesPayloadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasourcePayload>> {
        const response = await this.getAllReportDataSourcesPayloadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportRaw(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Report>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getReport.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportFromJSON(jsonValue));
    }

    /**
     */
    async getReport(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Report> {
        const response = await this.getReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportContentRaw(requestParameters: GetReportContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportContent>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getReportContent.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getReportContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/content`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportContentFromJSON(jsonValue));
    }

    /**
     */
    async getReportContent(requestParameters: GetReportContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportContent> {
        const response = await this.getReportContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportDataSourcePayloadRaw(requestParameters: GetReportDataSourcePayloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasourcePayload>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getReportDataSourcePayload.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getReportDataSourcePayload.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getReportDataSourcePayload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/data_sources/{id}/payload`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatasourcePayloadFromJSON(jsonValue));
    }

    /**
     */
    async getReportDataSourcePayload(requestParameters: GetReportDataSourcePayloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasourcePayload> {
        const response = await this.getReportDataSourcePayloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportDataSourcesRaw(requestParameters: GetReportDataSourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DataSource>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getReportDataSources.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getReportDataSources.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{id}/data_sources`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DataSourceFromJSON));
    }

    /**
     */
    async getReportDataSources(requestParameters: GetReportDataSourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DataSource>> {
        const response = await this.getReportDataSourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportRenderResourceRaw(requestParameters: GetReportRenderResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getReportRenderResource.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getReportRenderResource.');
        }

        if (requestParameters.renderReportRequestData === null || requestParameters.renderReportRequestData === undefined) {
            throw new runtime.RequiredError('renderReportRequestData','Required parameter requestParameters.renderReportRequestData was null or undefined when calling getReportRenderResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/renderer/{tenantId}/reports/{reportId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenderReportRequestDataToJSON(requestParameters.renderReportRequestData),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getReportRenderResource(requestParameters: GetReportRenderResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getReportRenderResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportStylesRaw(requestParameters: GetReportStylesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Style>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getReportStyles.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getReportStyles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/styles`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StyleFromJSON));
    }

    /**
     */
    async getReportStyles(requestParameters: GetReportStylesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Style>> {
        const response = await this.getReportStylesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportTranslationsRaw(requestParameters: GetReportTranslationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Translation>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getReportTranslations.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getReportTranslations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/translations`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TranslationFromJSON));
    }

    /**
     */
    async getReportTranslations(requestParameters: GetReportTranslationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Translation>> {
        const response = await this.getReportTranslationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getWorkbenchesRaw(requestParameters: GetWorkbenchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Workbench>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getWorkbenches.');
        }

        const queryParameters: any = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/workbenches/{tenantId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkbenchFromJSON));
    }

    /**
     */
    async getWorkbenches(requestParameters: GetWorkbenchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Workbench>> {
        const response = await this.getWorkbenchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tenantsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Tenant>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tenants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantFromJSON));
    }

    /**
     */
    async tenants(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Tenant>> {
        const response = await this.tenantsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async tenantsCreateRaw(requestParameters: TenantsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TenantSimplified>> {
        if (requestParameters.createTenantData === null || requestParameters.createTenantData === undefined) {
            throw new runtime.RequiredError('createTenantData','Required parameter requestParameters.createTenantData was null or undefined when calling tenantsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tenants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTenantDataToJSON(requestParameters.createTenantData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantSimplifiedFromJSON(jsonValue));
    }

    /**
     */
    async tenantsCreate(requestParameters: TenantsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantSimplified> {
        const response = await this.tenantsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateReportContentRaw(requestParameters: UpdateReportContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling updateReportContent.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling updateReportContent.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateReportContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/content`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateReportContent(requestParameters: UpdateReportContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateReportContentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateReportStyleRaw(requestParameters: UpdateReportStyleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Style>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling updateReportStyle.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling updateReportStyle.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateReportStyle.');
        }

        if (requestParameters.updateStyleData === null || requestParameters.updateStyleData === undefined) {
            throw new runtime.RequiredError('updateStyleData','Required parameter requestParameters.updateStyleData was null or undefined when calling updateReportStyle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/styles/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStyleDataToJSON(requestParameters.updateStyleData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StyleFromJSON(jsonValue));
    }

    /**
     */
    async updateReportStyle(requestParameters: UpdateReportStyleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Style> {
        const response = await this.updateReportStyleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateReportTranslationRaw(requestParameters: UpdateReportTranslationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Translation>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling updateReportTranslation.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling updateReportTranslation.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateReportTranslation.');
        }

        if (requestParameters.updateTranslationData === null || requestParameters.updateTranslationData === undefined) {
            throw new runtime.RequiredError('updateTranslationData','Required parameter requestParameters.updateTranslationData was null or undefined when calling updateReportTranslation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/translations/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTranslationDataToJSON(requestParameters.updateTranslationData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TranslationFromJSON(jsonValue));
    }

    /**
     */
    async updateReportTranslation(requestParameters: UpdateReportTranslationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Translation> {
        const response = await this.updateReportTranslationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateWorkbenchRaw(requestParameters: UpdateWorkbenchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workbench>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling updateWorkbench.');
        }

        if (requestParameters.workbenchId === null || requestParameters.workbenchId === undefined) {
            throw new runtime.RequiredError('workbenchId','Required parameter requestParameters.workbenchId was null or undefined when calling updateWorkbench.');
        }

        if (requestParameters.updateWorkbenchData === null || requestParameters.updateWorkbenchData === undefined) {
            throw new runtime.RequiredError('updateWorkbenchData','Required parameter requestParameters.updateWorkbenchData was null or undefined when calling updateWorkbench.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/workbenches/{tenantId}/workbenches/{workbenchId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"workbenchId"}}`, encodeURIComponent(String(requestParameters.workbenchId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkbenchDataToJSON(requestParameters.updateWorkbenchData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkbenchFromJSON(jsonValue));
    }

    /**
     */
    async updateWorkbench(requestParameters: UpdateWorkbenchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workbench> {
        const response = await this.updateWorkbenchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadReportEntitiesRaw(requestParameters: UploadReportEntitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Entity>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling uploadReportEntities.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling uploadReportEntities.');
        }

        if (requestParameters.dataSourceId === null || requestParameters.dataSourceId === undefined) {
            throw new runtime.RequiredError('dataSourceId','Required parameter requestParameters.dataSourceId was null or undefined when calling uploadReportEntities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        const response = await this.request({
            path: `/api/reports/{tenantId}/reports/{reportId}/data_sources/{dataSourceId}/entities`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"dataSourceId"}}`, encodeURIComponent(String(requestParameters.dataSourceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EntityFromJSON));
    }

    /**
     */
    async uploadReportEntities(requestParameters: UploadReportEntitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Entity>> {
        const response = await this.uploadReportEntitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
