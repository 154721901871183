import loadable from "@loadable/component"
import { Avatar, Group, Menu, Modal } from "@mantine/core"
import { IconSettings, IconSquarePlus, IconLogout } from "@tabler/icons-react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAccount } from "../accounts/context"

import { useSession } from "../sessions/context"
import { useTenant } from "../tenants/context"
import ActionCenterBar from "./ActionCenterBar"
import UserButton, { avatarText } from "./UserButton"

const AccountSettings = loadable(() => import("../accounts/AccountSettings"))
// TODO: handle modal in react-route sample in mantine Tabs documentaion.
const SessionTopBar = () => {
    const { logout } = useSession()
    const [settingsModal, setSettingsModal] = useState(false)
    const { account } = useAccount()
    const navigate = useNavigate()
    const { currentTenant, tenants } = useTenant();

    const tenantsMenu = tenants?.map(tenant => (
        <Menu.Item key={tenant.id} sx={($theme) => ({
            backgroundColor: tenant.id === currentTenant?.id ? $theme.colors.blue[0] : 'transparent'
        })} onClick={() => { navigate(`/workbenches/${tenant.id}`) }} icon={<Avatar radius="xl">{avatarText(tenant.name)}</Avatar>}>{tenant.name}</Menu.Item>
    ))

    return <>
        <Modal title="Account settings" size='70%' opened={settingsModal} onClose={() => setSettingsModal(false)}>
            <AccountSettings />
        </Modal>
        <Group sx={($theme) => ({
            padding: $theme.spacing.xs
        })} position="apart">
            <Menu >
                <Menu.Target>
                    <UserButton name={account?.displayName} tenant={currentTenant?.name} />
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Label>Switch Teams</Menu.Label>
                    {tenantsMenu}
                    <Menu.Item disabled={true} icon={<IconSquarePlus size={14} />} onClick={() => {
                        navigate('/setup')
                    }} >Create New Team</Menu.Item>
                    <Menu.Divider />
                    {currentTenant ? <>
                        <Menu.Label>Team</Menu.Label>
                        <Menu.Item disabled={true} onClick={() => { navigate(`/settings/${currentTenant?.id}/general`) }} icon={<IconSettings size={14} />} >Settings</Menu.Item>
                        <Menu.Divider />
                    </> : <></>}
                    <Menu.Label>Account</Menu.Label>
                    <Menu.Item icon={<IconSettings size={14} />} onClick={() => setSettingsModal(true)} >Settings</Menu.Item>
                    <Menu.Item icon={<IconLogout size={14} />} onClick={logout}>Logout</Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <ActionCenterBar />
        </Group>
    </>
}

export default SessionTopBar;