import { useEffect } from "react"
import { useNavigate } from "react-router"
import { useAccount } from "../accounts/context"

const RedirectToPersonal = () => {
    const navigate = useNavigate()
    const { account } = useAccount()
    useEffect(() => {
        if (account) navigate(`/workbenches/${account.id}`, { replace: true })
    }, [navigate, account])

    return <></>
}

export default RedirectToPersonal;