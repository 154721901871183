import { UnstyledButton, Group, Avatar, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { forwardRef } from "react";

export interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    tenant?: string;
    name?: string;
}

export const avatarText = (str: string | undefined) => {
    if (!str) return ''
    const parts = str.trim().split(' ')
    if (parts.length === 1) return parts[0].charAt(0).toUpperCase()
    else return parts[0].charAt(0).toUpperCase() + parts[parts.length - 1].charAt(0).toUpperCase()
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(({ tenant, name, ...others }, ref) => {

    return <UnstyledButton
        ref={ref}
        sx={(theme) => ({
            display: 'block',
            padding: theme.spacing.xs,
            color:   theme.fn.variant({ variant: 'light' , color: "bino-pink" }).color,

            '&:hover': {          
                color: theme.fn.variant({ variant: 'light' , color: "bino-pink" }).hover,
            },
        })}
        {...others}>
        <Group>
            <Avatar color="gray" radius="xl">{avatarText(tenant)}</Avatar>
            <div style={{ flex: 1 }}>
                <Text size="sm" weight={500}>
                    {tenant}
                </Text>

                <Text color="dimmed" size="xs">
                    {name}
                </Text>
            </div>
            <IconChevronRight size={16} />
        </Group>
    </UnstyledButton>
})

export default UserButton