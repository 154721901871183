import { ActionIcon } from "@mantine/core"
import { IconQuestionMark } from "@tabler/icons-react"

export interface HelpMeProps {
    helpPage: string;
}

const URI_BINO_BASE = "https://www.bino.bi/";
const MANUAL_PAGE = "docs/product/workbench/documentation/pages/";
const MANUAL_PAGEMESSAGE = "docs/product/workbench/documentation/pages/";
const MANUAL_PAGETITLE = "docs/product/workbench/documentation/pages/components/01a%20Page/#title-properties-titeleigenschaften";
const MANUAL_PAGEFOOTER = "docs/product/workbench/documentation/pages/components/01a%20Page/#footer-properties-fußzeilen-eigenschaften";
const MANUAL_LANDING = "docs/product/workbench/getting_started/doc-letgo-intro";
const MANUAL_TIME = "docs/product/workbench/documentation/pages/components/01e%20Timeline%20Chart";
const MANUAL_STRUCTURE = "docs/product/workbench/documentation/pages/components/01d%20Structure%20Chart";
const MANUAL_TABLE = "docs/product/workbench/documentation/pages/components/01c%20Table";
const MANUAL_TEXT = "docs/product/workbench/documentation/pages/components/01b%20Text";


export const HelpMeButton = ({ helpPage }: HelpMeProps) => {

    function openHelp(event: any): void {      
        let uri = URI_BINO_BASE;
        switch ( helpPage ) {
            case "MANUAL_PAGE":
                uri += MANUAL_PAGE;
                break;
            case "MANUAL_PAGETITLE":
                uri += MANUAL_PAGETITLE;
                break;
           case "MANUAL_PAGEMESSAGE>":
                uri += MANUAL_PAGEMESSAGE;
                break;
            case "MANUAL_PAGEFOOTER":
                uri += MANUAL_PAGEFOOTER;
                break;
            case "MANUAL_LANDING":
                uri += MANUAL_LANDING;
                break;    
            case "MANUAL_TIME":
                uri += MANUAL_TIME;
                break;  
            case "MANUAL_STRUCTURE":
                uri += MANUAL_STRUCTURE;
                break;
            case "MANUAL_TABLE":
                uri += MANUAL_TABLE;
                break;             
            case "MANUAL_TEXT":
                uri += MANUAL_TEXT;
                break;             
                                        
            default: 
                // 
                break;
         }
        
        window.open(uri, '_blank');
    }

    return <ActionIcon  variant="transparent"  onClick={openHelp} ><IconQuestionMark /></ActionIcon>
}