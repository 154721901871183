import { useInterval, useLocalStorage } from "@mantine/hooks";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useBackend } from "../backend/context";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useToken } from "./TokenContext";

export interface SessionContext {
    getId: () => string
    login: (email: string, password: string) => Promise<string>
    logout: () => Promise<void>
    cancel: (id: string) => Promise<void>
    state: "processing" | "loggedIn" | "loggedOut"
}

const Session = createContext<SessionContext>({
    login: function (email: string, password: string): Promise<string> {
        throw new Error("Function not implemented.");
    },
    state: "processing",
    logout: function (): Promise<void> {
        throw new Error("Function not implemented.");
    },
    cancel: function (id: string): Promise<void> {
        throw new Error("Function not implemented.");
    },
    getId: function (): string {
        throw new Error("Function not implemented.");
    }
})

export const useSession = () => {
    const context = useContext(Session);
    if (context === undefined) {
        throw new Error("useSession must be used inside a SessionProvider")
    }
    return context;
}

export interface SessionProviderProps {
    children: JSX.Element
}


const SessionProvider = ({ children }: SessionProviderProps) => {
    const [state, setState] = useState<"processing" | "loggedIn" | "loggedOut">("processing")
    const [unsafeRefreshToken, setUnsafeRefreshToken] = useLocalStorage<string>({ key: 'refreshToken', getInitialValueInEffect: false })
    const [refreshToken, setRefreshToken] = useState<JwtPayload | undefined>(undefined)
    const { jwtToken, setJwtToken } = useToken()
    const { api } = useBackend()
    const sessionUpdateInterval = useInterval(() => tokenRefresh(), 120000);

    useEffect(() => {
        if (state === "loggedIn") {
            sessionUpdateInterval.start()
        } else {
            sessionUpdateInterval.stop()
        }
        return sessionUpdateInterval.stop
    }, [state])

    const tokenRefresh = useCallback(async () => {
        try {
            const res = await api?.accountRefreshSession({ refreshSessionData: { refreshToken: unsafeRefreshToken } })
            setJwtToken(res!.jwt)
            setState("loggedIn")
        } catch {
            console.error("tokenRefresh failed")
            setState("loggedOut")
            setJwtToken(undefined)
        }
    }, [api, unsafeRefreshToken, setJwtToken])

    useEffect(() => {
        try {
            if (unsafeRefreshToken && unsafeRefreshToken !== "") {
                const token = jwt_decode<JwtPayload>(unsafeRefreshToken)
                setRefreshToken(token)
            } else {
                console.error("no refresh token")
                setState("loggedOut")
            }
        } catch {
            console.error("invalid refresh token")
            setState("loggedOut")
        }
    }, [unsafeRefreshToken, setRefreshToken])

    useEffect(() => {
        if (refreshToken) {
            if (refreshToken.exp! - Math.round(Date.now() / 1000) > (60 * 60)) {
                tokenRefresh()
            } else {
                console.dir(["refresh token expired", refreshToken])
                setState("loggedOut")
            }
        }
    }, [refreshToken])

    const jwtSub = useMemo(() => {
        if (!jwtToken) return ""
        const token = jwt_decode<JwtPayload>(jwtToken)
        return token.sub
    }, [jwtToken])

    const login = useCallback(async (email: string, password: string) => {
        const res = await api!.accountLogin({ loginData: { email, password } })
        setUnsafeRefreshToken(res.refreshToken!)
        setJwtToken(res.jwt)

        return jwt_decode<JwtPayload>(res.jwt).sub!
    }, [api, setJwtToken, setUnsafeRefreshToken])

    const logout = useCallback(async () => {
        await api!.accountLogout()
        setJwtToken(undefined)
        setUnsafeRefreshToken("")
    }, [api, setJwtToken, setUnsafeRefreshToken])

    const cancel = useCallback(async (id: string) => {
        // TODO: implement
        alert("...")
    }, [api])

    const getId = () => (jwtSub || "")

    return <Session.Provider value={{ login, logout, cancel, state, getId }}>{state === "processing" ? <></> : children}</Session.Provider>

}

export default SessionProvider
