import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useBackend } from "../backend/context";
import { Account as AccountModel, Details } from "../generated-sources/openapi";

export interface AccountContext {
    account?: AccountModel
    updateDetails: (details: Details) => Promise<void>
}

const Account = createContext<AccountContext>({
    updateDetails: function (details: Details): Promise<void> {
        throw new Error("Function not implemented.");
    }
})

export const useAccount = () => {
    const context = useContext(Account);
    if (context === undefined) {
        throw new Error("useAccount must be used inside a AccountProvider")
    }

    return context;
}

interface AccountProviderProps {
    children: JSX.Element
}

const AccountProvider = ({ children }: AccountProviderProps) => {
    const { api } = useBackend()
    const [account, setAccount] = useState<AccountModel>()

    const getDetails = useCallback(async () => {
        try {
            const account = await api!.accountDetails()
            setAccount(account)
        } catch {
            setAccount(undefined)
        }
    }, [api])

    const updateDetails = useCallback(async (details: Details) => {
        try {
            const account = await api!.accountUpdateDetails({ details })
            setAccount(account)
        } catch (e) {
            throw e
        }
    }, [api])


    //TODO: account called twice 
    useEffect(() => {
        if (!account) {
            getDetails()
        }
    }, [getDetails, account])

    return <Account.Provider value={{ account, updateDetails }}>{account ? children : <></>}</Account.Provider>
}

export default AccountProvider;