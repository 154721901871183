/* tslint:disable */
/* eslint-disable */
/**
 * Bino BI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Style
 */
export interface Style {
    /**
     * 
     * @type {string}
     * @memberof Style
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Style
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof Style
     */
    reportId: string;
    /**
     * 
     * @type {string}
     * @memberof Style
     */
    name: string;
    /**
     * 
     * @type {any}
     * @memberof Style
     */
    payload: any | null;
    /**
     * 
     * @type {Date}
     * @memberof Style
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the Style interface.
 */
export function instanceOfStyle(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tenantId" in value;
    isInstance = isInstance && "reportId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "payload" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function StyleFromJSON(json: any): Style {
    return StyleFromJSONTyped(json, false);
}

export function StyleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Style {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': json['tenantId'],
        'reportId': json['reportId'],
        'name': json['name'],
        'payload': json['payload'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function StyleToJSON(value?: Style | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'reportId': value.reportId,
        'name': value.name,
        'payload': value.payload,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

