
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSession } from "./context";
import { useToken } from "./TokenContext";
import { redirectToLogin } from "./utils";

import AccountProvider from "../accounts/context";
import TenantProvider from "../tenants/context";

const SessionOutlet = () => {
    const navigate = useNavigate()
    const { jwtToken } = useToken()
    const { state } = useSession()
    useEffect(() => {
        if (state === "loggedOut") redirectToLogin(navigate)('FAILURE', 'Invalid Session')
    }, [state])

    const content = <AccountProvider>
        <TenantProvider>
            <Outlet />
        </TenantProvider>
    </AccountProvider>

    return jwtToken ? content : <>Pending ...</>
}

export default SessionOutlet;