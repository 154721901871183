import { Container, createStyles } from "@mantine/core";
import { Outlet } from "react-router";
import SessionTopBar from "../components/SessionTopBar";

const useStyle = createStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    top: {
        backgroundColor: theme.fn.variant({ variant: 'light' , color: "gray" }).background,
    },
    main: {
        overflow: 'scroll',
        flex: 1,
        backgroundColor: theme.fn.variant({ variant: 'light' , color: "gray" }).background,
    }
}))

const AppFrameOutlet = () => {
    const { classes } = useStyle();
    return <div className={classes.wrapper}>
        <div className={classes.top}>
            <SessionTopBar /></div>
        <div className={classes.main}>
            <Container><Outlet /></Container>
        </div>
    </div>
}

export default AppFrameOutlet;