import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBackend } from "../backend/context";
import { Tenant } from "../generated-sources/openapi";

export interface TenantContext {
    currentTenant: Tenant | undefined;
    tenants: Tenant[] | undefined;
    refreshTenants: () => Promise<void>;
}

const Context = createContext<TenantContext>({
    currentTenant: undefined,
    tenants: undefined,
    refreshTenants: function (): Promise<void> {
        throw new Error("Function not implemented.");
    }
});

export const useTenant = () => {
    const context = useContext(Context);
    if (!context) throw new Error("useTenant must be used within a TenantProvider");
    return context;
}

const TenantProvider = ({ children }: { children: React.ReactNode }) => {
    const [currentTenant, setCurrentTenant] = useState<Tenant | undefined>(undefined);
    const [tenants, setTenants] = useState<Tenant[] | undefined>(undefined);
    const { api } = useBackend();

    const { tenantId } = useParams()

    const refreshTenants = async () => {
        const res = await api?.tenants()
        setTenants(res);
    }

    useEffect(() => {
        if (!tenants) return;
        const tenant = tenants.find(t => t.id === tenantId)
        setCurrentTenant(tenant)
    }, [tenantId, tenants]);

    useEffect(() => {
        refreshTenants();
    }, [])

    return <Context.Provider value={{ currentTenant, tenants, refreshTenants }}>
        {children}
    </Context.Provider>
}

export default TenantProvider;