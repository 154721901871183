/* tslint:disable */
/* eslint-disable */
/**
 * Bino BI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    userAgent: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    ipAddress: string;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    canceled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    status: string;
    /**
     * 
     * @type {Date}
     * @memberof Session
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Session
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Session interface.
 */
export function instanceOfSession(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userAgent" in value;
    isInstance = isInstance && "ipAddress" in value;
    isInstance = isInstance && "canceled" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function SessionFromJSON(json: any): Session {
    return SessionFromJSONTyped(json, false);
}

export function SessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Session {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userAgent': json['userAgent'],
        'ipAddress': json['ipAddress'],
        'canceled': json['canceled'],
        'status': json['status'],
        'updatedAt': (new Date(json['updatedAt'])),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function SessionToJSON(value?: Session | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userAgent': value.userAgent,
        'ipAddress': value.ipAddress,
        'canceled': value.canceled,
        'status': value.status,
        'updatedAt': (value.updatedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
    };
}

