import { createContext, useContext, useState } from "react";

export interface TokenContext {
    jwtToken?: string,
    setJwtToken: (token: string | undefined) => void
}

const Token = createContext<TokenContext>({
    setJwtToken: function (token: string | undefined): void {
    }
})

export const useToken = () => {
    const context = useContext(Token);
    if (context === undefined) {
        throw new Error("useToken must be used inside a TokenProvider")
    }

    return context;
}

export interface TokenProviderProps {
    children: JSX.Element
}

const TokenProvider = ({ children }: TokenProviderProps) => {
    const [jwtToken, setJwtToken] = useState<string>()
    return <Token.Provider value={{ jwtToken, setJwtToken }}>{children}</Token.Provider>
}

export default TokenProvider
