import { useCallback } from 'react';
import { createContext, useContext, useMemo } from 'react'
import { DefaultApi, Configuration } from "../generated-sources/openapi";
import { useToken } from '../sessions/TokenContext';

export interface BackendContext {
    api?: DefaultApi
}

const Backend = createContext<BackendContext>({})

export const useBackend = () => {
    const context = useContext(Backend);
    if (context === undefined) {
        throw new Error("useBackend must be used inside a BackendProvider")
    }

    return context;
}

export interface BackendProviderProps {
    children: JSX.Element
}

const BackendProvider = ({ children }: BackendProviderProps) => {
    const { jwtToken } = useToken()

    const accessToken = useCallback(async (name: string | undefined, scopes: string[] | undefined) => {
        if (jwtToken) {
            return jwtToken
        } else {
            throw new Error("jwt token missing")
        }

    }, [jwtToken])

    const context: BackendContext = useMemo(() => ({
        api: new DefaultApi(new Configuration({
            basePath: window.location.origin,
            accessToken,
        }))
    }), [accessToken])

    return <Backend.Provider value={context}>{children}</Backend.Provider>
}

export default BackendProvider;