/* tslint:disable */
/* eslint-disable */
/**
 * Bino BI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionToken
 */
export interface SessionToken {
    /**
     * 
     * @type {string}
     * @memberof SessionToken
     */
    jwt: string;
    /**
     * 
     * @type {string}
     * @memberof SessionToken
     */
    refreshToken?: string;
}

/**
 * Check if a given object implements the SessionToken interface.
 */
export function instanceOfSessionToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "jwt" in value;

    return isInstance;
}

export function SessionTokenFromJSON(json: any): SessionToken {
    return SessionTokenFromJSONTyped(json, false);
}

export function SessionTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jwt': json['jwt'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
    };
}

export function SessionTokenToJSON(value?: SessionToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jwt': value.jwt,
        'refreshToken': value.refreshToken,
    };
}

