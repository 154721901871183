import React from 'react';
import '@fontsource/noto-sans/100-italic.css';
import '@fontsource/noto-sans/200-italic.css';
import '@fontsource/noto-sans/300-italic.css';
import '@fontsource/noto-sans/400-italic.css';
import '@fontsource/noto-sans/500-italic.css';
import '@fontsource/noto-sans/600-italic.css';
import '@fontsource/noto-sans/700-italic.css';
import '@fontsource/noto-sans/800-italic.css';
import '@fontsource/noto-sans/900-italic.css';
import '@fontsource/noto-sans/100.css';
import '@fontsource/noto-sans/200.css';
import '@fontsource/noto-sans/300.css';
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/600.css';
import '@fontsource/noto-sans/700.css';
import '@fontsource/noto-sans/800.css';
import '@fontsource/noto-sans/900.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from 'react-error-boundary';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
  <ErrorBoundary fallback={<div>Oops an error occurred!</div>}>
    <App />
  </ErrorBoundary>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
