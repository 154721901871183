import BackendProvider from './backend/context';
import { BrowserRouter } from "react-router-dom";

import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import TokenProvider from './sessions/TokenContext';
import SessionProvider from './sessions/context';
import { Notifications } from '@mantine/notifications';

import AppRoutes from './app/AppRoutes';
import { ModalsProvider } from '@mantine/modals';




function App() {

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return <TokenProvider>
    <BackendProvider>
      <SessionProvider>
        <MantineProvider theme={{
          colorScheme,
          colors: {
            'bino-ci': ['#FF00FF', '#FF00FF', '#FF00FF', '#FF00FF', 'FF00FF', '#FF00FF', '#5CD8E5', '#FFE801', '#0E99AC', '#FFE801'],
            'bino-cyan': ['#E3FAFC', '#B9EEF9', '#69D8E4', '#26BFC4', '#02A0A9', '#009299', '#5CD8E5', '#74E8EB', '#8CF8F1', '#96FDF6'],  // berechnet chatgpt
            'bino-yellow': ["#FFFFFF", "#FFF899", "#FFF1AB", "#FFEBBC", "#FFE5CE", "#FFDFE0", "#FFE801", "#FFDC33", "#FFD465", "#FFCB97"],
            'bino-pink': ["#F9D0E4", "#F3A8CC", "#EE7FB3", "#E9579B", "#E42E82", "#DE056A", "#EF6BAA", "#F399B4", "#F7C1BE", "#FBE8C8"],
            'bino-orange': ["#FFFFFF", "#FFF899", "#FFF1AB", "#FFEBBC", "#FFE5CE", "#FFDFE0", "#FF5F1F", "#FF5F1F", "#FF5F1F", "#FF5F1F"],
          },
          primaryColor: (colorScheme === 'dark' ? 'cyan' : 'bino-cyan'),

        }} withGlobalStyles withNormalizeCSS>
          <ModalsProvider>
            <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
              <Notifications />
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </ColorSchemeProvider>
          </ModalsProvider>
        </MantineProvider>
      </SessionProvider>
    </BackendProvider>
  </TokenProvider>;
}

export default App;

