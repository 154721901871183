/* tslint:disable */
/* eslint-disable */
/**
 * Bino BI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkbenchType } from './WorkbenchType';
import {
    WorkbenchTypeFromJSON,
    WorkbenchTypeFromJSONTyped,
    WorkbenchTypeToJSON,
} from './WorkbenchType';

/**
 * 
 * @export
 * @interface CreateWorkbenchData
 */
export interface CreateWorkbenchData {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkbenchData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkbenchData
     */
    description?: string;
    /**
     * 
     * @type {WorkbenchType}
     * @memberof CreateWorkbenchData
     */
    workbenchType: WorkbenchType;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkbenchData
     */
    templateId: string;
    /**
     * 
     * @type {any}
     * @memberof CreateWorkbenchData
     */
    templatePayload?: any | null;
}

/**
 * Check if a given object implements the CreateWorkbenchData interface.
 */
export function instanceOfCreateWorkbenchData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "workbenchType" in value;
    isInstance = isInstance && "templateId" in value;

    return isInstance;
}

export function CreateWorkbenchDataFromJSON(json: any): CreateWorkbenchData {
    return CreateWorkbenchDataFromJSONTyped(json, false);
}

export function CreateWorkbenchDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWorkbenchData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'workbenchType': WorkbenchTypeFromJSON(json['workbenchType']),
        'templateId': json['templateId'],
        'templatePayload': !exists(json, 'templatePayload') ? undefined : json['templatePayload'],
    };
}

export function CreateWorkbenchDataToJSON(value?: CreateWorkbenchData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'workbenchType': WorkbenchTypeToJSON(value.workbenchType),
        'templateId': value.templateId,
        'templatePayload': value.templatePayload,
    };
}

