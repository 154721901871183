import { ActionIcon, Group, Indicator, Paper, Transition } from "@mantine/core"
import { useLocalStorage } from "@mantine/hooks";
import { IconSearch, IconBell, IconQuestionMark } from '@tabler/icons-react'
import { HelpMeButton } from "../report-editor/components/HelpMeButton";

const URI_BINO_BASE = "https://www.bino.bi/";
const URI_MANUAL = "docs/product/workbench/documentation/pages/";

const ActionCenterBar = () => {
    const [opened, setOpened] = useLocalStorage({ key: 'action-center-bar-state', defaultValue: false, deserialize: (v) => (v === "true"), serialize: (v) => (v ? 'true' : 'false'), getInitialValueInEffect: false })

    const hidden = [
        <ActionIcon key='help' size={"xl"}><IconQuestionMark size={18} /></ActionIcon>
    ]

    const active = [
        
        <ActionIcon key='search' size={"xl"}><IconSearch size={18} /></ActionIcon>,
        <Indicator key='notification' offset={14} color='cyan'><ActionIcon size={"xl"}><IconBell size={18} /></ActionIcon></Indicator>,
        <HelpMeButton helpPage={'MANUAL_LANDING'}/>,
       
        
    ]

    function openHelp(event: any): void {       
        window.open(URI_BINO_BASE + URI_MANUAL, '_blank');
    }


    return <Paper shadow="xs" radius={12} withBorder>
        <Group spacing={"s"}>
            <Transition mounted={opened} transition='slide-left'>
                {(styles) => (<Group style={{ ...styles }} spacing={"xs"}>
                    {hidden}
                </Group>)}
            </Transition>
            {active}
            
        </Group>
    </Paper >
}
/*<Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
            />*/
export default ActionCenterBar;