/* tslint:disable */
/* eslint-disable */
/**
 * Bino BI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WorkbenchType = {
    Report: 'Report'
} as const;
export type WorkbenchType = typeof WorkbenchType[keyof typeof WorkbenchType];


export function WorkbenchTypeFromJSON(json: any): WorkbenchType {
    return WorkbenchTypeFromJSONTyped(json, false);
}

export function WorkbenchTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkbenchType {
    return json as WorkbenchType;
}

export function WorkbenchTypeToJSON(value?: WorkbenchType | null): any {
    return value as any;
}

